import { Merge } from '../utils/commonUtils';
import { Offer } from './Offer';
import { User } from './User';

export type MiscOrderData = {
  origin?: string;
  receiveType?: string;
  recipient?: string;
  tyrkyteId?: string;
  targetGroupId?: number;
  gifts?: Array<string>;
  emailConsent: boolean;
  numOfPayments?: string;
  paymentMethod?: string;
  articleURL?: string;
  referrerSid?: string;
  referrerUrl?: string;
  sourceUrl?: string;
  sourceSid?: string;
};
export type Payer = {
  payerMasterasiakasId?: string;
  payerFirstName: string;
  payerLastName: string;
  payerStreetAddress?: string;
  payerCountry?: string;
  payerZipCode?: string;
  payerCity?: string;
  payerEmail: string;
  payerPhoneNumber: string;
};

export type OfferData = {
  offeringId: string;
  selectedMagazineId: string;
};

/**
 *
 * @param user
 * @returns
 */
export const orderRoleDataFromUser = (user: User): Payer => {
  const role = {} as Payer;
  role.payerMasterasiakasId = user.masterId;
  role.payerFirstName = user.firstName;
  role.payerLastName = user.lastName;
  role.payerStreetAddress = user.address?.streetName;
  role.payerCountry = user.address?.countryIso2;
  role.payerZipCode = user.address?.zipCode;
  role.payerCity = user.address?.city;
  role.payerEmail = user.contactInfo?.email;
  role.payerPhoneNumber = user.contactInfo?.phoneNumber;

  return role;
};

export const setRawOrderProperties = (rawOrder: RawOrder, {
  payerFirstName,
  payerLastName,
  payerPhoneNumber,
  payerStreetAddress,
  payerZipCode,
  payerCity,
}: Partial<RawOrder>): Partial<RawOrder> => ({
  ...rawOrder,
  payerFirstName,
  payerLastName,
  payerPhoneNumber,
  payerStreetAddress,
  payerZipCode,
  payerCity,
});

export const orderOfferDataFromOffer = (offer: Offer): OfferData => {
  const { magazines, targetGroupId } = offer;
  const chosenMagazine = magazines![0];

  const { id } = chosenMagazine;
  const offerData = {
    offeringId: offer.id ?? offer.offeringId,
    selectedMagazineId: id,
    targetGroupId,
  } as OfferData;
  return offerData;
};

/**
 * Initialize raw order object
 * @returns {RawOrder}
 */
export const initializeRawOrder = (): RawOrder => {
  const order = {} as RawOrder;
  order.origin = `${window.location.protocol}//${window.location.host}`;
  return order;
};

export type RawOrder = Merge<Merge<Payer, MiscOrderData>, OfferData>;
